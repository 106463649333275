

















































import { Component, Vue } from 'vue-property-decorator';
import StrategyModerateWarningChangeStrategyViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/hiring-flow/strategy-moderate-warning-change-strategy-view-model';

@Component({ name: 'StrategyModerateWarningChangeStrategy' })
export default class StrategyModerateWarningChangeStrategy extends Vue {
  strategy_warning_change_view_model = Vue.observable(
    new StrategyModerateWarningChangeStrategyViewModel(this),
  );

  created() {
    this.strategy_warning_change_view_model.initialize();
  }
}
